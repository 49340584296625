
import { defineComponent } from "vue";

interface Mark {
  mark: string;
  subjectName: string;
  min: string;
  max: string;
}

export default defineComponent({
  name: "marks-chart",
  components: {},
  props: { marksData: Array },
  data() {
    return {
      options: {},
      series: [] as { name: string; data: string[] }[],
      labelColor: "#3F4254",
      colors: ["#0095E8", "#67c23a", "#D9214E"],
      lightColors: ["#FFF5F8", "#e8fff3", "#F1FAFF"],
    };
  },

  watch: {
    marksData: function (value) {
      this.updateChart();
    },
  },

  created() {
    this.updateChart();
  },

  methods: {
    updateChart() {
      const subjectsSerie: string[] = [];
      const minSerie: string[] = [];
      const maxSerie: string[] = [];
      const studentSerie: string[] = [];

      (this.marksData as Mark[]).map((mark: Mark) => {
        subjectsSerie.push(mark.subjectName);
        minSerie.push(mark.min);
        maxSerie.push(mark.max);
        studentSerie.push(mark.mark);
      });

      this.updateOptions(subjectsSerie);
      this.updateSeries(maxSerie, studentSerie, minSerie);
    },

    updateSeries(
      maxSerie: string[],
      studentSerie: string[],
      minSerie: string[]
    ) {
      this.series = [
        {
          name: this.$t("report.maxMark"),

          data: maxSerie,
        },
        {
          name: this.$t("report.yourStudentMark"),
          data: studentSerie,
        },
        {
          name: this.$t("report.minMark"),

          data: minSerie,
        },
      ];
    },
    updateOptions(subjectsSerie) {
      this.options = {
        chart: {
          height: 300,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: false,
          },
        },

        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          color: this.colors,
        },
        xaxis: {
          type: "string",
          categories: subjectsSerie,
          labels: {
            show: false,
            style: {
              colors: this.labelColor,
              fontSize: "12px",
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {},
        },
        fill: {
          type: "gradient",
          gradient: {
            stops: [0, 100],
          },
        },
        color: this.colors,
        markers: {
          color: this.colors,
          strokeColor: this.lightColors,
          strokeWidth: 3,
        },
      };
    },
  },
});
