
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import marksChart from "./components/marksChart.vue";
import printBulletin from "./components/printBulletin.vue";

interface Subject {
  name: string;
  coef: number;
  mark: number;
  min: string;
  max: string;
  subSubjectMarks: {
    mark: number;
    maxMark: number;
    name: string;
  };
}

interface Module {
  name: string;
  coef: number;
  maxMoyenne: string;
  minMoyenne: string;
  subjects: Subject[];
}

interface Bulletin {
  available: boolean;
  moyenne: string;
  maxMoyenne: string;
  minMoyenne: string;
  fullname: string;
  students: number;
  classroomName: string;
  modules: Module[];
  fullName: string;
}

interface Mark {
  mark: number;
  subjectName: string;
  min: string;
  max: string;
  subSubjectMarks: {
    mark: number;
    maxMark: number;
    name: string;
  };
}

export default defineComponent({
  name: "BulletinVeySpecific",
  components: { marksChart, printBulletin },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const loading = ref(true);

    onMounted(() => {
      setCurrentPageTitle("dashboard.reportVerySpec");
    });

    const bulletin = ref<Bulletin>({} as Bulletin);
    const marksData = ref<Mark[]>([]);

    const studentID = window.localStorage.getItem("activeStudent");
    const trimester = String(store.getters.currentTrimester);

    ApiService.get(`/verySpecificBulletin/parent/${studentID}/${trimester}`)
      .then(({ data }) => {
        bulletin.value = data;
        if (bulletin.value.available) {
          marksData.value = [];
          bulletin.value.modules.map((module: Module) => {
            module.subjects.map((subject: Subject) => {
              const mark: Mark = {
                subjectName: subject.name,
                mark: subject.mark,
                min: subject.min,
                max: subject.max,
                subSubjectMarks: subject.subSubjectMarks,
              };
              marksData.value.push(mark);
            });
          });
        }

        loading.value = false;
      })
      .catch((e) => {
        console.log(e);
      });

    const tableRowClassName = ({
      row,
      rowIndex,
    }: {
      row: Mark;
      rowIndex: number;
    }) => {
      let classname = "";
      if (typeof row.mark == "number" && row.mark < 10) {
        classname = "danger-row";
      }
      if (!row.subSubjectMarks) classname += " hide-expand";
      return classname;
    };

    return {
      store,
      t,
      bulletin,
      loading,
      marksData,
      tableRowClassName,
    };
  },
  methods: {
    capitalize(s: string) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
});
